//
// Avatar connect
//

.avatar-connect {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-bottom: 2px dashed $avatar-connect-border-color;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
