//
// Delimiter
//

.delimiter-top,
.delimiter-bottom {
  position: relative;
  border: 0;
}

.delimiter-top {
  &:before {
    content: '';
    display: block;
    width: 80%;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -40%;
    height: 1px;
    background: radial-gradient(
      ellipse at center,
      $divider-border-color 0,
      rgba($white, 0) 75%
    );
  }
}

.delimiter-bottom {
  &:after {
    content: '';
    display: block;
    width: 80%;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -40%;
    height: 1px;
    background: radial-gradient(
      ellipse at center,
      $divider-border-color 0,
      rgba($white, 0) 75%
    );
  }
}

.delimiter-dark {
  &:before,
  &:after {
    background: radial-gradient(
      ellipse at center,
      rgba($white, 0.2) 0,
      rgba($black, 0) 75%
    );
  }
}
