//
// Floating labels
//

// Labels

.form-control-label {
  color: $input-label-color;
  font-size: $input-label-font-size;
  font-weight: $input-label-font-weight;
  text-transform: $input-label-text-transform;
}

// Forms with floating labels

.has-floating-label {
  position: relative;
}

.has-floating-label .control-label {
  font-size: $font-size-base;
  pointer-events: none;
  position: absolute;
  top: 50%;
  padding: 0 calc($input-padding-x / 2);
  margin: 0 calc($input-padding-x / 2);
  color: $input-placeholder-color;
  transform: translateY(-50%) scale(1);
  transform-origin: left top;
  transition: 0.3s;
  z-index: 1;
}

.has-floating-label .input-group .control-label {
  left: 2rem;
}

.has-floating-label.focused .control-label {
  padding: 0;
  margin: 0;
  top: -1.25rem;
  left: 0;
  transform: translateY(0) scale(0.85);
}

.has-floating-label .control-label-xl {
  font-size: $font-size-xl;
  padding: 0 calc($input-padding-x-xl / 2);
  margin: 0 calc($input-padding-x-xl / 2);
}

.has-floating-label .control-label-lg {
  font-size: $font-size-lg;
  padding: 0 calc($input-padding-x-lg / 2);
  margin: 0 calc($input-padding-x-lg / 2);
}

.has-floating-label .control-label-sm {
  font-size: $font-size-sm;
  padding: 0 calc($input-padding-x-sm / 2);
  margin: 0 calc($input-padding-x-sm / 2);
}
