//
// Nav tabs
//

.nav-tabs {
  .nav-item {
    margin-left: $nav-tabs-link-margin-x;
    margin-right: $nav-tabs-link-margin-x;
  }

  .nav-link {
    padding: $nav-tabs-link-padding-y 0;
    border-bottom: $nav-tabs-link-active-border-width solid transparent;
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;

    &:not(.active) {
      color: $nav-tabs-link-color;

      &:hover {
        color: $nav-tabs-link-hover-color;
      }
    }

    &.active {
      color: $nav-tabs-link-active-color;
    }
  }

  .nav-item:first-child {
    margin-left: 0;
  }

  .nav-item:last-child {
    margin-right: 0;
  }

  // Removes the primary color underline from dropdowns in .nav-tabs
  .nav-item.show .nav-link {
    border-color: transparent;
  }

  // Dark nav

  &.nav-dark {
    border-color: rgba($white, 0.2);

    .nav-link {
      &:not(.active) {
        color: rgba($white, 0.6);

        &:hover {
          color: rgba($white, 0.8);
        }
      }

      &.active {
        color: $white;
        border-color: $white;
      }
    }
  }
}
