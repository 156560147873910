//
// Datepicker
// based on Flatplick plugin
//

.flatpickr-calendar {
  border: 0;
  width: auto;
  margin-top: -2px;
  user-select: none;
  background: $datepicker-bg;
  @include box-shadow($datepicker-box-shadow);
  @include border-radius($datepicker-border-radius);

  &:before,
  &:after {
    display: none;
  }
}

.flatpickr-months {
  background-color: $datepicker-months-bg;
  @include border-top-radius($border-radius);

  .flatpickr-month {
    height: 60px;
  }

  .flatpickr-prev-month,
  .flatpickr-next-month {
    width: 35px;
    height: 35px;
    padding: 0;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $datepicker-months-color;
    top: 13px;
    @include transition($transition-bg);

    svg {
      fill: rgba($datepicker-months-color, 0.7);
    }

    &:hover {
      color: $datepicker-months-color;

      svg {
        fill: $datepicker-months-color;
      }
    }
  }

  .flatpickr-prev-month {
    margin-left: 15px;
  }

  .flatpickr-next-month {
    margin-right: 15px;
  }
}

.flatpickr-current-month {
  font-size: 1.125rem;
  color: $datepicker-months-color;
  padding-top: 18px;

  span.cur-month,
  input.cur-year {
    // font-weight: $font-weight-bold;
  }

  span.cur-month:hover {
    background-color: transparent;
  }

  .numInputWrapper {
    &:hover {
      background-color: transparent;
    }

    span {
      border: 0;
      right: -5px;
      padding: 0;

      &:after {
        left: 3px;
      }

      &.arrowUp {
        &:after {
          border-bottom-color: rgba(color-yiq(theme-color('primary')), 0.7);
        }

        &:hover {
          &:after {
            border-bottom-color: color-yiq(theme-color('primary'));
          }
        }
      }

      &.arrowDown {
        &:after {
          border-top-color: rgba(color-yiq(theme-color('primary')), 0.7);
        }

        &:hover {
          &:after {
            border-top-color: color-yiq(theme-color('primary'));
          }
        }
      }
    }
  }
}

span.flatpickr-weekday {
  font-weight: $font-weight-bold;
  color: $dropdown-heading-color;
}

.flatpickr-day {
  font-size: $font-size-sm;
  border: 0;
  color: $dropdown-link-color;
  @include border-radius($input-border-radius);

  &.today {
    color: theme-color('primary') !important;

    &:hover {
      background-color: $dropdown-link-active-bg;
      color: $dropdown-link-active-color;
    }
  }

  &:hover {
    background-color: $dropdown-link-hover-bg;
    color: $dropdown-link-hover-color;
  }

  &.selected {
    background-color: theme-color('primary');
    color: color-yiq(theme-color('primary')) !important;

    &:hover {
      background-color: theme-color('primary');
      color: color-yiq(theme-color('primary'));
    }
  }
}

.numInputWrapper {
  span {
    &:hover {
      background-color: transparent;
    }
  }
}

.flatpickr-time {
  border-top: 1px solid $dropdown-divider-bg;
}

.flatpickr-innerContainer {
  padding: 15px;
}
