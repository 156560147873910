//
// Close
//

.close {
  & > span:not(.sr-only) {
    color: rgba($black, 0.5);
  }

  &:hover,
  &:focus {
    & > span:not(.sr-only) {
      color: rgba($black, 0.8);
    }
  }
}
