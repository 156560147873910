//
// Avatar group
//

.avatar-group {
  display: inline-block;
  line-height: 1;

  .avatar {
    z-index: 1;
    @include transition(margin 0.15s ease-in-out);

    img {
      border: $avatar-stack-gutter solid $avatar-stack-border-color;
    }

    &:hover {
      z-index: 2;
    }
  }

  .avatar + .avatar {
    margin-left: -1.25rem;
    @include border-left-radius(0);
  }

  .avatar-sm + .avatar-sm {
    margin-left: -1rem;
  }

  &:hover {
    .avatar {
      @include border-left-radius($avatar-border-radius);
    }

    .avatar-sm {
      @include border-left-radius($avatar-border-radius-sm);
    }
  }
}

.hover-avatar-ungroup:hover {
  .avatar:not(:first-child) {
    margin-left: 0;
  }
}
