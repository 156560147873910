//
// Circle badge
//

// General styles

.badge-circle {
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  width: 1.25rem;
  height: 1.25rem;
  vertical-align: middle;
  font-size: 0.75rem;
  font-weight: 600;

  &.badge-xs {
    width: 1rem;
    height: 1rem;
    font-size: 0.5rem;
  }

  &.badge-md {
    width: 1.5rem;
    height: 1.5rem;
  }

  &.badge-lg {
    width: 2rem;
    height: 2rem;
  }

  svg {
    margin: 0;
  }
}
