//
// Custom color
// custom checkbox/radio as color
//

.custom-color {
  list-style: none;
  padding: 0;

  &:after,
  &:before {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }

  label {
    width: 2.25rem;
    height: 2.25rem;
    float: left;
    padding: 0.375rem;
    margin-bottom: 0;
    margin-right: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    border: 1px solid transparent;
    border-radius: $border-radius;
    @include transition($transition-base);
    transform: scale(0.95);

    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    i,
    svg {
      opacity: 0;
      color: rgba(255, 255, 255, 0.9);
    }
  }

  input {
    left: -9999px;
    position: absolute;

    &:checked + label {
      transform: scale(1.1);
      opacity: 1;

      i,
      svg {
        opacity: 1;
      }
    }
  }
}

.custom-color-wide {
  label {
    height: 1.5rem;
  }
}

// Sizes

.custom-color-xl {
  label {
    width: 5rem;
    height: 5rem;
  }

  &.custom-color-wide {
    label {
      width: 5rem;
      height: 3.5rem;
    }
  }
}

.custom-color-lg {
  label {
    width: 3.25rem;
    height: 3.25rem;
  }
}

.custom-color-sm {
  label {
    width: 1.25rem;
    height: 1.25rem;
  }
}
