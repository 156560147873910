//
// Section
//

section.slice {
  background-color: section-color('primary');
}

.slice {
  position: relative;
  padding-top: $spacer * 4;
  padding-bottom: $spacer * 4;
}

.slice-xl {
  padding-top: $spacer * 8;
  padding-bottom: $spacer * 8;
}

.slice-lg {
  padding-top: $spacer * 6;
  padding-bottom: $spacer * 6;
}

.slice-sm {
  padding-top: $spacer * 2;
  padding-bottom: $spacer * 2;
}

.slice:not(.border-top):not(.border-bottom):not(.delimiter-bottom):not(
    [class*='bg-']
  ):not(.section-rotate) {
  &
    + .slice:not(.border-top):not(.border-bottom):not(.delimiter-top):not(
      [class*='bg-']
    ) {
    padding-top: 0;
  }
}

.slice-video {
  min-height: 600px;
}
