// Sidenav toggler

.sidenav-toggler {
  border: 0;
  background: transparent;
  cursor: pointer;
}

.sidenav-toggler-inner,
.sidenav-toggler-line {
  width: 18px;
  @include transition($transition-base);
}

.sidenav-toggler-inner {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    left: -11px;
    top: -14px;
    border-radius: 50%;
    transform: scale(0);
    @include transition($transition-cubic-bezier);
  }
}

.sidenav-toggler-line {
  height: 2px;
  background-color: $navbar-light-color;
  display: block;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 3px;
  }
}

.sidenav-toggler {
  &.active {
    .sidenav-toggler-inner {
      &:before {
        transform: scale(1);
      }
    }

    .sidenav-toggler-line {
      background-color: theme-color('primary]');

      &:first-child {
        width: 13px;
        transform: translateX(5px);
      }

      &:last-child {
        width: 13px;
        transform: translateX(5px);
      }
    }
  }
}

.navbar-dark,
.sidenav-toggler-dark {
  .sidenav-toggler-line {
    background-color: $navbar-dark-color !important;
  }
}
