//
// Avatar
//

.avatar {
  position: relative;
  color: $avatar-color;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: $avatar-font-size;
  font-weight: $avatar-font-weight;
  height: $avatar-width;
  width: $avatar-width;
  @include border-radius($avatar-border-radius);

  img {
    width: 100%;
    @include border-radius($avatar-border-radius);
  }

  &.rounded-circle {
    img {
      border-radius: 50%;
    }
  }

  span {
    background-color: $avatar-bg;
  }

  + .avatar {
    margin-left: 0.25rem;
  }

  + .avatar-content {
    display: inline-block;
    margin-left: 0.75rem;
  }
}

// Sizes

.avatar-2xl {
  width: $avatar-width-2xl;
  height: $avatar-width-2xl;
  font-size: $avatar-font-size-2xl;
}

.avatar-xl {
  width: $avatar-width-xl;
  height: $avatar-width-xl;
  font-size: $avatar-font-size-xl;
}

.avatar-lg {
  width: $avatar-width-lg;
  height: $avatar-width-lg;
  font-size: $avatar-font-size-lg;
}

.avatar-sm {
  width: $avatar-width-sm;
  height: $avatar-width-sm;
  font-size: $avatar-font-size-sm;
  @include border-radius($avatar-border-radius-sm);
}

.avatar-xs {
  width: $avatar-width-xs;
  height: $avatar-width-xs;
  font-size: $avatar-font-size-xs;
  @include border-radius($avatar-border-radius-xs);
}

.btn-avatar-action {
  display: none;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 100%;
  font-size: 12px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  color: #ccc;
}

.avatar:hover {
  .btn-avatar-action {
    display: block;
  }
}
