//
// Positions
// Can be used on absolute/relative positioned elements
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .position#{$infix}-absolute {
      position: absolute !important;
    }

    .position#{$infix}-relative {
      position: relative !important;
    }

    .position#{$infix}-static {
      position: static !important;
    }
  }
}
