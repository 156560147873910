//
// Badge floating
//

.btn {
  .badge-floating {
    position: absolute;
    top: -50%;
    right: 0.5rem;
    transform: translate(50%, 50%);
    margin: 0;
    border: 3px solid;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    &.badge-circle {
      right: 0;
    }
  }
}
