//
// Scrollable
//

.scrollable-x {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.scrollable-y {
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
