//
// Custom alphanumeric
//

.custom-alphanumeric {
  list-style: none;
  padding: 0;

  & + .custom-alphanumeric {
    margin-left: 0.25rem;
  }

  &:after,
  &:before {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }

  > :not(:last-child) {
    label {
      margin-right: 0.375rem;
    }
  }

  label {
    width: 2.25rem;
    height: 2.25rem;
    float: left;
    padding: 0.375rem 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-600;
    font-weight: 400;
    font-size: $font-size-sm;
    background: transparent;
    text-transform: uppercase;
    transition: all 0.3s ease;
    transform: scale(0.95);
    background: $custom-control-indicator-bg;
    border: $custom-control-indicator-border-width solid
      $custom-control-indicator-border-color;
    border-radius: $custom-checkbox-indicator-border-radius;

    img {
      max-width: 100%;
    }

    i,
    svg {
      opacity: 0.8;
    }

    &:hover {
      cursor: pointer;
      border-color: $custom-control-indicator-hover-border-color;
    }
  }

  input {
    left: -9999px;
    position: absolute;

    &:checked ~ label {
      color: $custom-control-indicator-checked-border-color;
      border-color: $custom-control-indicator-checked-border-color;
      transform: scale(1.1);

      i,
      svg {
        opacity: 1;
      }
    }
  }
}

// Sizes

.custom-alphanumeric-xl {
  label {
    width: 5rem;
    height: 5rem;
    font-size: $font-size-xl;
  }
}

.custom-alphanumeric-lg {
  label {
    width: 3.25rem;
    height: 3.25rem;
    font-size: $font-size-lg;
  }
}

.custom-alphanumeric-sm {
  label {
    width: 1.25rem;
    height: 1.25rem;
  }
}
