//
// Map
//

.map-canvas {
  position: relative;
  width: 100%;
  height: 400px;
  border-radius: $border-radius;
}
