//
// Custom checkbox
//

.checklist {
  .custom-control-input {
    &:checked ~ .custom-control-label {
      text-decoration: line-through;
    }
  }

  .card {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
