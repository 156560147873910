// Clearfix

.floatfix {
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}

// Overflows

.overflow-visible {
  overflow: visible !important;
}

.overflow-visible-x {
  overflow-x: visible !important;
}

.overflow-visible-y {
  overflow-y: visible !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-hidden-x {
  overflow-x: hidden !important;
}

.overflow-hidden-y {
  overflow-y: hidden !important;
}

.overflow-scroll-x {
  overflow-x: scroll !important;
}

.overflow-scroll-y {
  overflow-y: scroll !important;
}

// Opacities

[class*='alpha'] {
  @if $enable-transitions {
    transition: $transition-base;
  }
}

// Fading elements

[class*='faded'] {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 30%;
  }
}

.faded-top {
  &:before {
    top: 0;
    background: -moz-linear-gradient(
      bottom,
      transparent 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
    background: -webkit-linear-gradient(
      bottom,
      transparent 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
    background: linear-gradient(
      to top,
      transparent 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= '#00000000', endColorstr='#a6000000',GradientType=0 );
  }
}

.faded-bottom {
  &:before {
    bottom: 0;
    background: -moz-linear-gradient(
      top,
      transparent 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
    background: -webkit-linear-gradient(
      top,
      transparent 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
    background: linear-gradient(
      to bottom,
      transparent 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= '#00000000', endColorstr='#a6000000',GradientType=0 );
  }
}
