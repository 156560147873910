//
// Modal
//

.modal-content {
  border: 0;
  @if $enable-rounded {
    border-radius: $modal-content-border-radius;
  } @else {
    border-radius: 0;
  }
}

.modal-fluid {
  .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
  }

  .modal-content {
    border-radius: 0;
  }
}

.modal-open {
  max-height: 100vh;
}

// Positions

.modal-dialog-aside {
  position: absolute;
  margin: 0;
}

// Color variations

@each $color, $value in $theme-colors {
  .modal-#{$color} {
    @include modal-variant($value);
  }
}
