//
// Dismissible alert
// bootstrap overrides + new elements
//

.alert-dismissible {
  .alert-group-prepend {
    display: block;
  }

  .close {
    top: 50%;
    right: $alert-padding-x;
    transform: translateY(-50%);
    padding: 0;
  }
}
