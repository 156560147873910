//
// Content
//

.side-content {
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    height: 100%;
    background-color: section-color('secondary');

    @include media-breakpoint-up(sm) {
      width: 2000px;
      @include border-radius(4rem 0 0 4rem !important);
    }

    @include media-breakpoint-down(xs) {
      width: 100%;
      left: 0;
    }
  }

  &-inner {
    @include media-breakpoint-up(sm) {
      padding: 2rem 0 2.5rem 2.5rem;
    }

    @include media-breakpoint-down(xs) {
      padding: 2rem 0;
    }
  }
}
