//
// Transulcent cards
//

.card-translucent-dark {
  background: rgba(255, 255, 255, 0.2);
}

.card-translucent-light {
  background: rgba(0, 0, 0, 0.2);
}
