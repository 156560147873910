//
// Card table
// table with spaced rows and syles with the card's properties
//

.table-cards {
  border-collapse: separate;
  border-spacing: 0 1rem;

  thead,
  tbody {
    th,
    td {
      border: 0 !important;
      position: relative;
      background-color: transparent;
    }

    td {
      padding: 1rem;
    }

    th {
      padding: 0 1rem;
    }
  }

  tbody tr {
    border-radius: $card-border-radius;

    @if $enable-shadows {
      @include box-shadow($card-shadow);
    }

    th {
      border-radius: $card-border-radius 0 0 $card-border-radius;

      &:after {
        border-radius: $card-border-radius 0 0 $card-border-radius;
        border-left: 1px solid $card-border-color;
      }
    }

    td {
      &:last-child {
        border-radius: 0 $card-border-radius $card-border-radius 0;

        &:after {
          border-radius: 0 $card-border-radius $card-border-radius 0;
          border-right: 1px solid $card-border-color;
        }
      }
    }

    th,
    td {
      background-color: $card-bg;
    }
  }

  &.table-hover {
    tbody > tr {
      &:hover {
        th,
        td {
          background-color: $table-hover-bg;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .table-cards.table-scale--hover {
    tr {
      &:hover {
        transform: scale(1.02);
      }
    }
  }
}
