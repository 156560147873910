//
// Fading mask
//

.mask-fade-bottom {
  background: linear-gradient(
    0deg,
    rgba($black, 1) 0%,
    rgba(0, 0, 0, 1) 0%,
    rgba($black, 0) 60%
  );
}
